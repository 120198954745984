import React from 'react';
import { graphql } from 'gatsby';
import '../fragments';

import {
  renderBoolean,
  renderPercentageWithExplainer,
  renderArrayOfStrings,
  renderMonthAndYear,
  renderPriceRange,
} from '../utils/characteristicsRenderFunctions';
import { ENTITY_TYPES_WITH_REVIEWS } from '../constants';
import { RawPortableText } from '../types';
import { RealEstateProjectCharacteristics } from '../graphql-fragments/RealEstateProject';
import { CharacteristicsProps } from '../components/ui/CharacteristicsBox';

import EntityPage from './EntityPage';

import * as styles from './EntityPage.module.scss';

export const query = graphql`
  query GetRealEstateProjectPageById($id: String!) {
    sanityRealEstateProject(id: { eq: $id }) {
      ...RealEstateProject
    }
    sanityRealEstateProjectPage {
      sendAMessageForm {
        introText
        _rawWarningText(resolveReferences: { maxDepth: 6 })
        bottomNoteText
      }
      bookACallMessageForm {
        introText
        _rawWarningText(resolveReferences: { maxDepth: 6 })
        bottomNoteText
      }
    }
  }
`;

interface RealEstateProjectProps {
  data: {
    sanityRealEstateProject: RealEstateProjectCharacteristics;
    sanityRealEstateProjectPage?: {
      sendAMessageForm?: {
        introText?: string;
        _rawWarningText?: RawPortableText;
        bottomNoteText?: string;
      };
      bookACallMessageForm?: {
        introText?: string;
        _rawWarningText?: RawPortableText;
        bottomNoteText?: string;
      };
    };
  };
}

const RealEstateProject = ({ data }: RealEstateProjectProps): React.ReactElement => {
  const {
    _id,
    name,
    slug,
    hubspotInternalValue,
    logoImage,
    personName,
    personImage,
    mainVideoUrl,
    approved,
    introText,
    _rawDescription,
    bookIntroCallUrl,
    developerCompanyName,
    realEstateMarketSectors,
    totalNumberOfUnitsInTheProject,
    whatYouPurchase,
    unitPriceRange,
    currentProjectState,
    postConstruction,
    buybackOption,
    whichGVcategories,
    seo,
  } = data.sanityRealEstateProject;

  const realEstateProjectCharacteristics: Array<CharacteristicsProps> = [
    { title: 'Auto Book Intro Call', value: bookIntroCallUrl && renderBoolean(!!bookIntroCallUrl) },
    { title: 'Developer company name', value: developerCompanyName },
    {
      title: 'Real estate market sectors',
      value: renderArrayOfStrings(realEstateMarketSectors.map(sector => sector.title)),
    },
    { title: 'Total number of units in the project', value: totalNumberOfUnitsInTheProject },
    { title: 'What you purchase', value: whatYouPurchase },
    {
      title: 'Unit price range',
      value:
        unitPriceRange?.minimumPrice &&
        unitPriceRange.maximumPrice &&
        renderPriceRange(unitPriceRange?.minimumPrice, unitPriceRange?.maximumPrice),
    },
    {
      title: 'Current project state',
      subItems: [
        {
          title: 'All building permissions granted',
          value: renderBoolean(currentProjectState.allBuildingPermissionsGranted),
        },
        {
          title: 'Construction Start Date',
          value: renderMonthAndYear(currentProjectState.constructionStartDate),
        },
        {
          title: 'Construction Completion Date',
          value: renderMonthAndYear(currentProjectState.constructionCompletionDate),
        },
      ],
    },
    {
      title: 'Post Construction:',
      subItems: [
        { title: 'Property manager', value: postConstruction.propertyManager },
        {
          title: 'All building permissions granted',
          value: renderBoolean(currentProjectState.allBuildingPermissionsGranted),
        },
        {
          title: 'Management Fee',
          value: renderPercentageWithExplainer(postConstruction.managementFee),
        },
        {
          title: 'Target annual returns',
          value: renderPercentageWithExplainer(postConstruction.targetAnnualReturns),
        },
        { title: 'Fixed return', value: renderBoolean(postConstruction.fixedReturn) },
      ],
    },
    { title: 'Buyback option', value: renderBoolean(buybackOption) },
    {
      title: 'Which category of Golden Visa',
      value: renderArrayOfStrings(whichGVcategories.map(category => category.title)),
    },
  ];

  return (
    <EntityPage
      entityType="realEstateProject"
      entityId={_id}
      slug={slug.current}
      hubspotInternalValue={hubspotInternalValue}
      isApproved={approved}
      hasResponsiblePerson={true}
      personImage={personImage}
      personName={personName}
      image={logoImage}
      topic={'real estate project'}
      title={name}
      text={introText}
      content={_rawDescription}
      characteristics={realEstateProjectCharacteristics}
      videoUrl={mainVideoUrl}
      hasReviews={ENTITY_TYPES_WITH_REVIEWS.includes('realEstateProject')}
      sendAMessageFormTexts={data.sanityRealEstateProjectPage?.sendAMessageForm}
      bookACallFormTexts={data.sanityRealEstateProjectPage?.bookACallMessageForm}
      bookIntroCallUrl={bookIntroCallUrl}
      seo={seo}
    />
  );
};

export default RealEstateProject;
